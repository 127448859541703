exports.components = {
  "component---breaktime-theme-src-demo-pages-demo-background-sizing-jsx": () => import("./../../../../breaktime-theme/src/demo/pages/demo/background-sizing.jsx" /* webpackChunkName: "component---breaktime-theme-src-demo-pages-demo-background-sizing-jsx" */),
  "component---breaktime-theme-src-demo-pages-demo-barilla-demo-jsx": () => import("./../../../../breaktime-theme/src/demo/pages/demo/barilla-demo.jsx" /* webpackChunkName: "component---breaktime-theme-src-demo-pages-demo-barilla-demo-jsx" */),
  "component---breaktime-theme-src-demo-pages-demo-buffalo-jsx": () => import("./../../../../breaktime-theme/src/demo/pages/demo/buffalo.jsx" /* webpackChunkName: "component---breaktime-theme-src-demo-pages-demo-buffalo-jsx" */),
  "component---breaktime-theme-src-demo-pages-demo-event-bubbler-jsx": () => import("./../../../../breaktime-theme/src/demo/pages/demo/event-bubbler.jsx" /* webpackChunkName: "component---breaktime-theme-src-demo-pages-demo-event-bubbler-jsx" */),
  "component---breaktime-theme-src-demo-pages-demo-filtering-jsx": () => import("./../../../../breaktime-theme/src/demo/pages/demo/filtering.jsx" /* webpackChunkName: "component---breaktime-theme-src-demo-pages-demo-filtering-jsx" */),
  "component---breaktime-theme-src-demo-pages-demo-gallery-jsx": () => import("./../../../../breaktime-theme/src/demo/pages/demo/gallery.jsx" /* webpackChunkName: "component---breaktime-theme-src-demo-pages-demo-gallery-jsx" */),
  "component---breaktime-theme-src-demo-pages-demo-gen-ai-demo-jsx": () => import("./../../../../breaktime-theme/src/demo/pages/demo/gen-ai-demo.jsx" /* webpackChunkName: "component---breaktime-theme-src-demo-pages-demo-gen-ai-demo-jsx" */),
  "component---breaktime-theme-src-demo-pages-demo-instant-win-6-jsx": () => import("./../../../../breaktime-theme/src/demo/pages/demo/instant-win-6.jsx" /* webpackChunkName: "component---breaktime-theme-src-demo-pages-demo-instant-win-6-jsx" */),
  "component---breaktime-theme-src-demo-pages-demo-instant-win-api-jsx": () => import("./../../../../breaktime-theme/src/demo/pages/demo/instant-win-api.jsx" /* webpackChunkName: "component---breaktime-theme-src-demo-pages-demo-instant-win-api-jsx" */),
  "component---breaktime-theme-src-demo-pages-demo-instant-win-v-3-jsx": () => import("./../../../../breaktime-theme/src/demo/pages/demo/instant-win-v3.jsx" /* webpackChunkName: "component---breaktime-theme-src-demo-pages-demo-instant-win-v-3-jsx" */),
  "component---breaktime-theme-src-demo-pages-demo-modal-1-jsx": () => import("./../../../../breaktime-theme/src/demo/pages/demo/modal1.jsx" /* webpackChunkName: "component---breaktime-theme-src-demo-pages-demo-modal-1-jsx" */),
  "component---breaktime-theme-src-demo-pages-demo-modal-2-jsx": () => import("./../../../../breaktime-theme/src/demo/pages/demo/modal2.jsx" /* webpackChunkName: "component---breaktime-theme-src-demo-pages-demo-modal-2-jsx" */),
  "component---breaktime-theme-src-demo-pages-demo-open-ai-jsx": () => import("./../../../../breaktime-theme/src/demo/pages/demo/open-ai.jsx" /* webpackChunkName: "component---breaktime-theme-src-demo-pages-demo-open-ai-jsx" */),
  "component---breaktime-theme-src-demo-pages-demo-poll-jsx": () => import("./../../../../breaktime-theme/src/demo/pages/demo/poll.jsx" /* webpackChunkName: "component---breaktime-theme-src-demo-pages-demo-poll-jsx" */),
  "component---breaktime-theme-src-demo-pages-demo-quiz-jsx": () => import("./../../../../breaktime-theme/src/demo/pages/demo/quiz.jsx" /* webpackChunkName: "component---breaktime-theme-src-demo-pages-demo-quiz-jsx" */),
  "component---breaktime-theme-src-demo-pages-demo-recipe-jsx": () => import("./../../../../breaktime-theme/src/demo/pages/demo/recipe.jsx" /* webpackChunkName: "component---breaktime-theme-src-demo-pages-demo-recipe-jsx" */),
  "component---breaktime-theme-src-demo-pages-demo-recipe-typing-jsx": () => import("./../../../../breaktime-theme/src/demo/pages/demo/recipe-typing.jsx" /* webpackChunkName: "component---breaktime-theme-src-demo-pages-demo-recipe-typing-jsx" */),
  "component---breaktime-theme-src-demo-pages-demo-review-2-jsx": () => import("./../../../../breaktime-theme/src/demo/pages/demo/review-2.jsx" /* webpackChunkName: "component---breaktime-theme-src-demo-pages-demo-review-2-jsx" */),
  "component---breaktime-theme-src-demo-pages-demo-sample-step-full-jsx": () => import("./../../../../breaktime-theme/src/demo/pages/demo/sample-step-full.jsx" /* webpackChunkName: "component---breaktime-theme-src-demo-pages-demo-sample-step-full-jsx" */),
  "component---breaktime-theme-src-demo-pages-demo-sample-step-jsx": () => import("./../../../../breaktime-theme/src/demo/pages/demo/sample-step.jsx" /* webpackChunkName: "component---breaktime-theme-src-demo-pages-demo-sample-step-jsx" */),
  "component---breaktime-theme-src-demo-pages-demo-skittles-demo-jsx": () => import("./../../../../breaktime-theme/src/demo/pages/demo/skittles-demo.jsx" /* webpackChunkName: "component---breaktime-theme-src-demo-pages-demo-skittles-demo-jsx" */),
  "component---breaktime-theme-src-demo-pages-demo-skittles-demo-summer-jsx": () => import("./../../../../breaktime-theme/src/demo/pages/demo/skittles-demo-summer.jsx" /* webpackChunkName: "component---breaktime-theme-src-demo-pages-demo-skittles-demo-summer-jsx" */),
  "component---breaktime-theme-src-demo-pages-demo-slideshow-jsx": () => import("./../../../../breaktime-theme/src/demo/pages/demo/slideshow.jsx" /* webpackChunkName: "component---breaktime-theme-src-demo-pages-demo-slideshow-jsx" */),
  "component---breaktime-theme-src-demo-pages-demo-smsmodal-jsx": () => import("./../../../../breaktime-theme/src/demo/pages/demo/smsmodal.jsx" /* webpackChunkName: "component---breaktime-theme-src-demo-pages-demo-smsmodal-jsx" */),
  "component---breaktime-theme-src-demo-pages-demo-sponsored-footer-jsx": () => import("./../../../../breaktime-theme/src/demo/pages/demo/sponsored-footer.jsx" /* webpackChunkName: "component---breaktime-theme-src-demo-pages-demo-sponsored-footer-jsx" */),
  "component---breaktime-theme-src-demo-pages-demo-spot-the-diff-demo-multi-jsx": () => import("./../../../../breaktime-theme/src/demo/pages/demo/spot-the-diff-demo-multi.jsx" /* webpackChunkName: "component---breaktime-theme-src-demo-pages-demo-spot-the-diff-demo-multi-jsx" */),
  "component---breaktime-theme-src-demo-pages-demo-spot-the-diff-demo-single-jsx": () => import("./../../../../breaktime-theme/src/demo/pages/demo/spot-the-diff-demo-single.jsx" /* webpackChunkName: "component---breaktime-theme-src-demo-pages-demo-spot-the-diff-demo-single-jsx" */),
  "component---breaktime-theme-src-demo-pages-demo-spot-the-diff-sponsored-jsx": () => import("./../../../../breaktime-theme/src/demo/pages/demo/spot-the-diff-sponsored.jsx" /* webpackChunkName: "component---breaktime-theme-src-demo-pages-demo-spot-the-diff-sponsored-jsx" */),
  "component---breaktime-theme-src-demo-pages-demo-super-break-jsx": () => import("./../../../../breaktime-theme/src/demo/pages/demo/super-break.jsx" /* webpackChunkName: "component---breaktime-theme-src-demo-pages-demo-super-break-jsx" */),
  "component---breaktime-theme-src-demo-pages-demo-svg-jsx": () => import("./../../../../breaktime-theme/src/demo/pages/demo/svg.jsx" /* webpackChunkName: "component---breaktime-theme-src-demo-pages-demo-svg-jsx" */),
  "component---breaktime-theme-src-demo-pages-demo-victo-test-jsx": () => import("./../../../../breaktime-theme/src/demo/pages/demo/victo-test.jsx" /* webpackChunkName: "component---breaktime-theme-src-demo-pages-demo-victo-test-jsx" */),
  "component---breaktime-theme-src-demo-pages-demo-video-share-jsx": () => import("./../../../../breaktime-theme/src/demo/pages/demo/video-share.jsx" /* webpackChunkName: "component---breaktime-theme-src-demo-pages-demo-video-share-jsx" */),
  "component---breaktime-theme-src-demo-pages-demo-wrong-nav-demo-jsx": () => import("./../../../../breaktime-theme/src/demo/pages/demo/wrong-nav-demo.jsx" /* webpackChunkName: "component---breaktime-theme-src-demo-pages-demo-wrong-nav-demo-jsx" */),
  "component---breaktime-theme-src-demo-pages-test-auth-jsx": () => import("./../../../../breaktime-theme/src/demo/pages/test/auth.jsx" /* webpackChunkName: "component---breaktime-theme-src-demo-pages-test-auth-jsx" */),
  "component---breaktime-theme-src-demo-pages-test-complete-jsx": () => import("./../../../../breaktime-theme/src/demo/pages/test/complete.jsx" /* webpackChunkName: "component---breaktime-theme-src-demo-pages-test-complete-jsx" */),
  "component---breaktime-theme-src-demo-pages-test-event-jsx": () => import("./../../../../breaktime-theme/src/demo/pages/test/event.jsx" /* webpackChunkName: "component---breaktime-theme-src-demo-pages-test-event-jsx" */),
  "component---breaktime-theme-src-demo-pages-test-featured-jsx": () => import("./../../../../breaktime-theme/src/demo/pages/test/featured.jsx" /* webpackChunkName: "component---breaktime-theme-src-demo-pages-test-featured-jsx" */),
  "component---breaktime-theme-src-demo-pages-test-modal-jsx": () => import("./../../../../breaktime-theme/src/demo/pages/test/modal.jsx" /* webpackChunkName: "component---breaktime-theme-src-demo-pages-test-modal-jsx" */),
  "component---breaktime-theme-src-demo-pages-test-nav-points-jsx": () => import("./../../../../breaktime-theme/src/demo/pages/test/nav-points.jsx" /* webpackChunkName: "component---breaktime-theme-src-demo-pages-test-nav-points-jsx" */),
  "component---breaktime-theme-src-demo-pages-test-sweeps-jsx": () => import("./../../../../breaktime-theme/src/demo/pages/test/sweeps.jsx" /* webpackChunkName: "component---breaktime-theme-src-demo-pages-test-sweeps-jsx" */),
  "component---breaktime-theme-src-demo-pages-test-token-jsx": () => import("./../../../../breaktime-theme/src/demo/pages/test/token.jsx" /* webpackChunkName: "component---breaktime-theme-src-demo-pages-test-token-jsx" */),
  "component---breaktime-theme-src-demo-pages-test-upload-jsx": () => import("./../../../../breaktime-theme/src/demo/pages/test/upload.jsx" /* webpackChunkName: "component---breaktime-theme-src-demo-pages-test-upload-jsx" */),
  "component---breaktime-theme-src-pages-404-error-textblock-jsx": () => import("./../../../../breaktime-theme/src/pages/404/ErrorTextblock.jsx" /* webpackChunkName: "component---breaktime-theme-src-pages-404-error-textblock-jsx" */),
  "component---breaktime-theme-src-pages-404-jsx": () => import("./../../../../breaktime-theme/src/pages/404.jsx" /* webpackChunkName: "component---breaktime-theme-src-pages-404-jsx" */),
  "component---breaktime-theme-src-pages-about-jsx": () => import("./../../../../breaktime-theme/src/pages/about.jsx" /* webpackChunkName: "component---breaktime-theme-src-pages-about-jsx" */),
  "component---breaktime-theme-src-pages-exclusive-content-jsx": () => import("./../../../../breaktime-theme/src/pages/exclusive-content.jsx" /* webpackChunkName: "component---breaktime-theme-src-pages-exclusive-content-jsx" */),
  "component---breaktime-theme-src-pages-how-it-works-jsx": () => import("./../../../../breaktime-theme/src/pages/how-it-works.jsx" /* webpackChunkName: "component---breaktime-theme-src-pages-how-it-works-jsx" */),
  "component---breaktime-theme-src-pages-index-jsx": () => import("./../../../../breaktime-theme/src/pages/index.jsx" /* webpackChunkName: "component---breaktime-theme-src-pages-index-jsx" */),
  "component---breaktime-theme-src-pages-info-official-rules-js": () => import("./../../../../breaktime-theme/src/pages/info/official-rules.js" /* webpackChunkName: "component---breaktime-theme-src-pages-info-official-rules-js" */),
  "component---breaktime-theme-src-pages-info-sweeps-guidelines-js": () => import("./../../../../breaktime-theme/src/pages/info/sweeps-guidelines.js" /* webpackChunkName: "component---breaktime-theme-src-pages-info-sweeps-guidelines-js" */),
  "component---breaktime-theme-src-pages-instant-win-demo-jsx": () => import("./../../../../breaktime-theme/src/pages/instant-win-demo.jsx" /* webpackChunkName: "component---breaktime-theme-src-pages-instant-win-demo-jsx" */),
  "component---breaktime-theme-src-pages-preview-jsx": () => import("./../../../../breaktime-theme/src/pages/preview.jsx" /* webpackChunkName: "component---breaktime-theme-src-pages-preview-jsx" */),
  "component---breaktime-theme-src-pages-privacy-js": () => import("./../../../../breaktime-theme/src/pages/privacy.js" /* webpackChunkName: "component---breaktime-theme-src-pages-privacy-js" */),
  "component---breaktime-theme-src-pages-profile-index-js": () => import("./../../../../breaktime-theme/src/pages/profile/index.js" /* webpackChunkName: "component---breaktime-theme-src-pages-profile-index-js" */),
  "component---breaktime-theme-src-pages-profile-reset-password-jsx": () => import("./../../../../breaktime-theme/src/pages/profile/resetPassword.jsx" /* webpackChunkName: "component---breaktime-theme-src-pages-profile-reset-password-jsx" */),
  "component---breaktime-theme-src-pages-profile-verify-email-jsx": () => import("./../../../../breaktime-theme/src/pages/profile/verifyEmail.jsx" /* webpackChunkName: "component---breaktime-theme-src-pages-profile-verify-email-jsx" */),
  "component---breaktime-theme-src-pages-qa-jsx": () => import("./../../../../breaktime-theme/src/pages/qa.jsx" /* webpackChunkName: "component---breaktime-theme-src-pages-qa-jsx" */),
  "component---breaktime-theme-src-pages-settings-jsx": () => import("./../../../../breaktime-theme/src/pages/settings.jsx" /* webpackChunkName: "component---breaktime-theme-src-pages-settings-jsx" */),
  "component---breaktime-theme-src-pages-terms-js": () => import("./../../../../breaktime-theme/src/pages/terms.js" /* webpackChunkName: "component---breaktime-theme-src-pages-terms-js" */),
  "component---breaktime-theme-src-templates-category-template-jsx": () => import("./../../../../breaktime-theme/src/templates/CategoryTemplate.jsx" /* webpackChunkName: "component---breaktime-theme-src-templates-category-template-jsx" */),
  "component---breaktime-theme-src-templates-editorial-template-jsx": () => import("./../../../../breaktime-theme/src/templates/EditorialTemplate.jsx" /* webpackChunkName: "component---breaktime-theme-src-templates-editorial-template-jsx" */),
  "component---breaktime-theme-src-templates-i-frame-template-jsx": () => import("./../../../../breaktime-theme/src/templates/IFrameTemplate.jsx" /* webpackChunkName: "component---breaktime-theme-src-templates-i-frame-template-jsx" */),
  "component---breaktime-theme-src-templates-recent-template-jsx": () => import("./../../../../breaktime-theme/src/templates/RecentTemplate.jsx" /* webpackChunkName: "component---breaktime-theme-src-templates-recent-template-jsx" */),
  "component---breaktime-theme-src-templates-scrolling-sponsored-template-jsx": () => import("./../../../../breaktime-theme/src/templates/ScrollingSponsoredTemplate.jsx" /* webpackChunkName: "component---breaktime-theme-src-templates-scrolling-sponsored-template-jsx" */),
  "component---breaktime-theme-src-templates-sponsored-single-template-jsx": () => import("./../../../../breaktime-theme/src/templates/SponsoredSingleTemplate.jsx" /* webpackChunkName: "component---breaktime-theme-src-templates-sponsored-single-template-jsx" */),
  "component---breaktime-theme-src-templates-sweeps-winner-template-jsx": () => import("./../../../../breaktime-theme/src/templates/SweepsWinnerTemplate.jsx" /* webpackChunkName: "component---breaktime-theme-src-templates-sweeps-winner-template-jsx" */),
  "component---breaktime-theme-src-templates-tag-template-jsx": () => import("./../../../../breaktime-theme/src/templates/TagTemplate.jsx" /* webpackChunkName: "component---breaktime-theme-src-templates-tag-template-jsx" */),
  "component---breaktime-theme-src-templates-type-template-jsx": () => import("./../../../../breaktime-theme/src/templates/TypeTemplate.jsx" /* webpackChunkName: "component---breaktime-theme-src-templates-type-template-jsx" */),
  "component---breaktime-theme-src-templates-video-share-redirect-template-jsx": () => import("./../../../../breaktime-theme/src/templates/VideoShareRedirectTemplate.jsx" /* webpackChunkName: "component---breaktime-theme-src-templates-video-share-redirect-template-jsx" */),
  "component---src-pages-rewards-components-rewards-template-jsx": () => import("./../../../src/pages/rewards/components/rewardsTemplate.jsx" /* webpackChunkName: "component---src-pages-rewards-components-rewards-template-jsx" */),
  "component---src-pages-rewards-components-tile-jsx": () => import("./../../../src/pages/rewards/components/Tile.jsx" /* webpackChunkName: "component---src-pages-rewards-components-tile-jsx" */),
  "component---src-pages-rewards-index-js": () => import("./../../../src/pages/rewards/index.js" /* webpackChunkName: "component---src-pages-rewards-index-js" */),
  "component---src-pages-rewards-winners-jsx": () => import("./../../../src/pages/rewards/winners.jsx" /* webpackChunkName: "component---src-pages-rewards-winners-jsx" */)
}

